/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2021
 *
 * *************************************************************************
 */

export const CALENDAR_MILESTONE_CONSTANTS = {
    defaultWidth: 20,
    minWidth: 56,
};
