<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<section>
    <ss-profile [profile]="profile"
                [ownProfile]="true">
    </ss-profile>
    <ss-pat-content></ss-pat-content>
</section>
