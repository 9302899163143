/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2023
 *
 * *************************************************************************
 */

import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {Subscription} from 'rxjs';

import {BreakpointHelper} from '../../../misc/helpers/breakpoint.helper';
import {BREAKPOINTS_RANGE as breakpointsRange} from '../../../ui/constants/breakpoints.constant';

@Component({
    selector: 'ss-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    public showBrandLogo = false;

    private _disposableSubscriptions: Subscription = new Subscription();

    constructor(private _breakpointHelper: BreakpointHelper) {
    }

    ngOnInit() {
        this._setShowBrandLogo();
        this._setSubscriptions();
    }

    ngOnDestroy() {
        this._unsetSubscriptions();
    }

    private _setShowBrandLogo(): void {
        this.showBrandLogo = !this._breakpointHelper.isCurrentBreakpoint(breakpointsRange.xs);
    }

    private _setSubscriptions(): void {
        this._disposableSubscriptions.add(
            this._breakpointHelper.breakpointChange()
                .subscribe(() => this._setShowBrandLogo()));
    }

    private _unsetSubscriptions(): void {
        this._disposableSubscriptions.unsubscribe();
    }
}
