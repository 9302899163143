<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<ss-daycard-reason-capture [dayCardReasonsRequestStatus]="rfvListRequestStatus"
                           [dayCardReasons]="rfvList"
                           [requestStatus]="requestStatus"
                           (submitForm)="handleSubmit($event)"
                           (cancelForm)="handleCancel()">
    {{'DayCard_ReasonNotDone_Description' | translate}}
</ss-daycard-reason-capture>
