<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-traffic-light" [ngStyle]="getStyles()" [ngClass]="getClasses()" [attr.data-automation]="'ss-traffic-light'">
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" [attr.fill]="getFillColor()" [attr.data-automation]="'ss-traffic-light-circle'"/>
    </svg>
</div>
