<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div *ngIf="isLoading" class="ss-loader">
    <div class="ss-loader__icon">
        <svg>
            <circle class="path" cx="25" cy="25" r="20" stroke-width="2" stroke-miterlimit="10"></circle>
        </svg>
    </div>
</div>
