<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-rfv-cr-chart-bar-grouped">
    <ngx-charts-bar-vertical-2d
        [barPadding]="graphSettings.barPadding"
        [customColors]="graphSettings.customColors"
        [groupPadding]="graphSettings.groupPadding"
        [results]="graphSettings.results"
        [roundEdges]="graphSettings.roundEdges"
        [xAxis]="graphSettings.xAxis"
        [xAxisTickFormatting]="graphSettings.xAxisTickFormatting"
        [yAxis]="graphSettings.yAxis"
        [yAxisTickFormatting]="graphSettings.yAxisTickFormatting"
        [yScaleMax]="graphSettings.yScaleMax">
        <ng-template #tooltipTemplate let-model="model">
            <ss-project-kpis-tooltip [list]="getRfvDescription(model)" [showFooter]="false"></ss-project-kpis-tooltip>
        </ng-template>
    </ngx-charts-bar-vertical-2d>
</div>

