<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<ss-paginator
        [pageSizes]="pageSizes"
        [paginatorData]="paginatorData"
        (pageChangedEvent)="onPageChange($event)"
        (itemsPerPageChangedEvent)="onItemsPerPageChange($event)">
</ss-paginator>
