<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-paginator-items">
    <div class="ss-paginator-items__items-wrapper">
        <span *ngFor="let item of items; let i = index"
              [attr.data-automation]="'paginator-item-' + item"
              (click)="selectItem(i, $event)"
              class="ss-paginator-items__item"
              [ngClass]="getClasses(i)">{{item}}</span>
    </div>
</div>
