<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2023
  ~
  ~ **************************************************************************
  -->

<section *ngIf="items?.length"
         class="ss-chip-list"
         [attr.data-automation]="'chip-list'">
    <header class="ss-chip-list__header">
        <h6 [attr.data-automation]="'chip-list-label'">{{listLabel}}</h6>
        <button
            *ngIf="showRemoveAll"
            class="ss-button ss-button--small ss-button--secondary-clear-blue ss-button--no-padding"
            (click)="handleRemoveAllChips()"
            [attr.data-automation]="'chip-list-remove-all'">
            {{removeAllLabel}}
        </button>
    </header>
    <ul class="ss-chip-list__chips">
        <li *ngFor="let item of items | slice:0:currentMaximumChips ; trackBy: trackByFn"
            [attr.data-automation]="'chip-list-item'">
            <ss-chip [item]="item"
                     (remove)="handleRemoveChip($event)">
            </ss-chip>
        </li>
    </ul>
    <ss-show-more
        *ngIf="canShowMore"
        (showMoreEnabled)=" onShowMoreChange($event)">
    </ss-show-more>
</section>
