<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2021
  ~
  ~ **************************************************************************
  -->

<div class="ss-brand">
    <a aria-label="Home" [routerLink]="homeUrl" [attr.data-automation]="'bosch-brand-logo-link'">
        <img *ngIf="showBrandLogo"
             src="/assets/images/logo/brand_bosch_rms_horizontal.svg"
             alt="Bosch RefinemySite Logo"
             [attr.data-automation]="'bosch-brand-logo'">
        <img
            *ngIf="!showBrandLogo"
            src="/assets/images/logo/brand_bosch.svg"
            alt="Bosch Logo"
            [attr.data-automation]="'bosch-logo'">
    </a>
</div>
