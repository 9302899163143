<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<a *ngIf="phone" class="ss-phone-link" [href]="getLink()" (click)="$event.stopPropagation()">
    <ss-icon *ngIf="showIcon" [name]="'call'" [color]="iconColor" [attr.data-automation]="'phone-link-icon'"></ss-icon>
    <span>{{getLinkText()}}</span>
</a>
