<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-task-news-label">
    <div *ngIf="getUncriticalTopics> 0"
         [attr.data-automation]="'project-task-news-label-uncritical'"
         class="ss-project-task-news-label__uncritical">
        <ss-icon [name]="'topic'"
                 [color]="iconColorNews">
        </ss-icon>
        <span>{{getUncriticalTopics}}</span>
    </div>
    <div *ngIf="getCriticalTopics > 0"
         [attr.data-automation]="'project-task-news-label-critical'"
         class="ss-project-task-news-label__critical">
        <ss-icon [name]="'topic-critical'"
                 [color]="iconColorNews">
        </ss-icon>
        <span>{{getCriticalTopics}}</span>
    </div>
</div>
