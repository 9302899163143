/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {Component} from '@angular/core';

@Component({
    templateUrl: './project-task-children.component.html'
})
export class ProjectTaskChildrenComponent {
}
