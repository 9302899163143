<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="container-no-padding">
    <ss-project-workareas-toolbar></ss-project-workareas-toolbar>
</div>
<div class="ss-project-workareas__list-container">
    <ss-project-workareas-list></ss-project-workareas-list>
</div>
