<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2023
  ~
  ~ **************************************************************************
  -->

<div class="ss-calendar-workarea-row-header">
    <span [attr.data-automation]="'ss-calendar-workarea-row-header-title'">
        {{ workarea.position ? workarea.position + ". " + workarea.name : "" + workarea.name }}
    </span>
</div>