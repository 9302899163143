<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<ss-supergraphic></ss-supergraphic>
<ss-announcement-list></ss-announcement-list>
<ss-navbar></ss-navbar>
<div class="ss-user__header-wrapper">
    <ss-header ssSticky [stickyTop]="6" [stickyId]="'header'" [stickyIndex]="101"></ss-header>
</div>
<main class="ss-main">
    <router-outlet></router-outlet>
</main>
