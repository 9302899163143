/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

export enum GenderEnum {
    female,
    male,
    other
}
