<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-task-status-label">
    <ss-task-status-icon [status]="task.status"></ss-task-status-icon>
    <ss-task-status-dropdown [task]="task"></ss-task-status-dropdown>
</div>
