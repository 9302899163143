<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<ss-icon [name]="icon.name"
         [color]="icon.color"
         class="ss-day-card-indicator">
</ss-icon>
