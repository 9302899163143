<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<a *ngIf="email" class="ss-mail-link" [href]="getLink()" target="_top" (click)="$event.stopPropagation()">
    <ss-icon *ngIf="showIcon" [name]="'letter'" [color]="iconColor" [attr.data-automation]="'mail-link-icon'"></ss-icon>
    <span>{{getLinkText()}}</span>
</a>
