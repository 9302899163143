<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-kpis-tab-navigation">
    <ss-navigation-tabs [routes]="routes"></ss-navigation-tabs>
</div>
