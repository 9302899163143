<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-overview-card" (click)="handleNavigateProjectDashboard()">
    <div class="ss-project-overview-card-content">
        <h2 class="ss-project-overview-card-content__title"
            [attr.data-automation]="'project-overview-card-title-' + projectOverviewModel.id">
            {{projectOverviewModel.title}}
        </h2>
        <div class="ss-project-overview-card-content__details">
            <ss-task-donut-chart class="ss-project-overview-card-content__details-chart"
                                 [attr.data-automation]="'project-overview-card-chart-' + projectOverviewModel.id"
                                 [taskChart]="projectOverviewModel.donutSlice">
            </ss-task-donut-chart>
            <div *ssIfMediaQuery="['sm', 'md', 'lg', 'xl']"
                 class="ss-project-overview-card-content__details-topics">
                <div (click)="handleSetCriticalTopicFilter()">
                    <h2 class="ss-project-overview-card-content__details-topics-amount"
                        [attr.data-automation]="'project-overview-card-critical-topics-' + projectOverviewModel.id">
                        {{projectOverviewModel.criticalTopics}}
                    </h2>
                    <h4 class="ss-project-overview-card-content__details-topics-detail">
                        {{getTopicLabelKey() | translate}}
                    </h4>
                </div>
            </div>
        </div>
    </div>
    <div class="ss-project-overview-card-picture" [ssBackgroundImage]="projectOverviewModel.image"></div>
</div>
