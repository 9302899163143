/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

export const Z_INDEX = {
    index__1: 1,
    index__2: 2,
    index__3: 3,
    index__4: 4,
    index__5: 5,
    index__6: 6,
    index__10: 10,
    index__100: 100,
    index__1000: 1000,
    index__10000: 10000,
    index__100000: 100000,
    z_max: 9999999999,
};
