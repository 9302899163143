<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-paginator-entries" [ngStyle]="{'width': width}">
    <span>{{start}}-{{end}}/{{totalEntries}}</span>
</div>
