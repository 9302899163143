/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

export class SaveProjectParticipantResource {
    constructor(public role: string) {
    }
}
