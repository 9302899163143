<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2023
  ~
  ~ **************************************************************************
  -->

<div class="ss-milestone-location-label">
    <ss-icon class="ss-milestone-location-label__icon"
             [name]="'workarea'"></ss-icon>
    <span *ngIf="milestone.header"
          class="ss-milestone-location-label__location"
          [attr.data-automation]="'milestone-location-label-location'">{{'Generic_TopRow' | translate}}</span>
    <span *ngIf="workArea"
          class="ss-milestone-location-label__location"
          [attr.data-automation]="'milestone-location-label-location'">{{workArea.position}}. {{workArea.name}}</span>
    <button *ngIf="!milestone.header && !workArea"
            class="ss-button ss-button--secondary-clear-blue"
            (click)="handleAddLocation()"
            [attr.data-automation]="'milestone-location-label-button'">
        {{'Generic_AddLocation' | translate}}
    </button>
</div>
