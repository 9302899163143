/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {Component} from '@angular/core';

@Component({
    selector: 'ss-project-participants-children',
    templateUrl: './project-participants-children.component.html',
})
export class ProjectParticipantsChildrenComponent {
}
