/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {
    Component,
    HostListener,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    LangChangeEvent,
    TranslateService
} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

import {BreakpointsEnum} from '../shared/ui/constants/breakpoints.constant';

@Component({
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
    /**
     * @description Property with source path of brand identifier
     * @type {string}
     */
    public srcBrandIdentifier: string;

    /**
     * @description Property with css class brand identifier
     * @type {string}
     */
    public classBrandIdentifier: string;

    private _currentLanguage: string;

    private _disposableSubscriptions: Subscription = new Subscription();

    @HostListener('window:resize', ['$event'])
    private _onResize() {
        this._setBrandmark();
        this._setBrandIdentifier(this._currentLanguage);
    }

    constructor(private _translateService: TranslateService) {
    }

    ngOnInit() {
        this._setCurrentLanguage();
        this._setBrandIdentifier(this._currentLanguage);
        this._setBrandmark();
        this._setSubscriptions();
    }

    ngOnDestroy() {
        this._unsetSubscriptions();
    }

    private _setBrandIdentifier(language: string): void {
        const pathBrandMark: string = this._isTabletResolution() ? 'lifeclip' : 'logo';
        this.srcBrandIdentifier = `/assets/images/${pathBrandMark}/${language}.png`;
    }

    private _setBrandmark(): void {
        this.classBrandIdentifier = this._isTabletResolution() ? 'ss-lifeclip' : 'ss-auth-wrapper__logo';
    }

    private _setCurrentLanguage(): void {
        this._currentLanguage = this._translateService.defaultLang;
    }

    private _setSubscriptions(): void {
        this._disposableSubscriptions.add(this._translateService.onDefaultLangChange
            .subscribe((event: LangChangeEvent) => {
                this._currentLanguage = event.lang;
                this._setBrandIdentifier(event.lang);
            }));
    }

    private _unsetSubscriptions() {
        this._disposableSubscriptions.unsubscribe();
    }

    private _isTabletResolution(): boolean {
        return window.innerWidth >= BreakpointsEnum.sm;
    }
}
