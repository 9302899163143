<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2023
  ~
  ~ **************************************************************************
  -->

<div class="ss-header">
    <ss-breadcrumb [attr.data-automation]="'header-breadcrumb'">
        <ss-brand [showBrandLogo]="showBrandLogo"></ss-brand>
    </ss-breadcrumb>
    <div class="ss-header__actions">
        <ss-job-list-button></ss-job-list-button>

        <ss-notification-list class="ss-header__action-item"
            [attr.data-automation]="'header-notifications'">
        </ss-notification-list>

        <ss-help-section class="ss-header__action-item-question-mark"
            [attr.data-automation]="'header-help-section'"></ss-help-section>

        <ss-account-menu [attr.data-automation]="'header-account-menu'">
        </ss-account-menu>
    </div>
</div>
