<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-tasks-edit">
    <ss-loader [isLoading]="isLoading"></ss-loader>
    <ss-project-tasks-capture
            #editCapture
            [mode]="captureMode"
            [hasDefaultValues]="defaultValues"
            (onCancel)="onClose.emit($event)"
            (onSubmit)="handleUpdate($event)"
            (onUpdate)="handleUpdate($event)">
    </ss-project-tasks-capture>
</div>
