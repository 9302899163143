<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->
<ng-template let-content #defaultTemplate>
    <span [class]="cellClass"
          [attr.title]="showTooltip ? content : null"
          [ngStyle]="cellStyle">
        {{content}}
    </span>
</ng-template>
