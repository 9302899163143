<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<ss-tasks-toolbar [drawerRef]="filterDrawerViewContainerRef"></ss-tasks-toolbar>
<div class="ss-project-task__wrapper">
    <ss-project-tasks-content class="ss-project-task__wrapper-list"></ss-project-tasks-content>
    <ng-container #filterDrawer></ng-container>
</div>
