<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-card-company">
    <span [ngStyle]="spanStyle" [attr.data-automation]="'company-name'">{{getDisplayName()}}</span>
</div>
