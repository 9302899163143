<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<section class="ss-project-kpis-tooltip" [attr.data-automation]="'ss-project-kpis-tooltip'">
    <ng-container *ngIf="hasWeeklyDayCardsNotFulfilled()">
        <header class="ss-project-kpis-tooltip__header">
            <h5 *ngIf="hasTitle()" class="ss-project-kpis-tooltip__title" [attr.data-automation]="'ss-project-kpis-tooltip-title'">{{ 'Project_Kpis_RfvInWeekTitle' | translate }} {{ weekLabel }}</h5>
        </header>
        <ss-project-kpis-list [data]="list" [showTotal]="showTotal"></ss-project-kpis-list>
        <ng-container *ngIf="showFooter" [ngTemplateOutlet]="footer"></ng-container>
    </ng-container>
    <ng-container *ngIf="hasWeeklyDayCardsFulfilled()">
        <header class="ss-project-kpis-tooltip__header">
            <h5 class="ss-project-kpis-tooltip__title" [attr.data-automation]="'ss-project-kpis-tooltip-title'">{{ 'Project_Kpis_AllDayCardsFulfilled' | translate }}</h5>
        </header>
        <ng-container *ngIf="showFooter" [ngTemplateOutlet]="footer"></ng-container>
    </ng-container>
    <ng-container *ngIf="noWeeklyDayCardsPlanned()">
        <header class="ss-project-kpis-tooltip__header">
            <h5 class="ss-project-kpis-tooltip__title" [attr.data-automation]="'ss-project-kpis-tooltip-title'">{{ 'Project_Kpis_NoPlannedDayCards' | translate }}</h5>
        </header>
    </ng-container>
</section>
<ng-template #footer>
    <footer class="ss-project-kpis-tooltip__footer">
        <ss-traffic-light-with-label [value]="value" [settings]="trafficLightWithLabelSettings"></ss-traffic-light-with-label>
    </footer>
</ng-template>
