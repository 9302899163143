/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {Component} from '@angular/core';

@Component({
    selector: 'ss-user-children',
    templateUrl: './user-children.component.html',
})
export class UserChildrenComponent {
}
