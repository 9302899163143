<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2021
  ~
  ~ **************************************************************************
  -->

<div class="ss-milestone-date-label">
    <ss-icon class="ss-milestone-date-label__icon"
             [name]="'calendar'"></ss-icon>
    <span [attr.data-automation]="'milestone-date-label-date'">{{formattedDate}}</span>
</div>
