<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<ss-pagination
        [paginatorDataSelectorFunction]="paginatorDataSelectorFunction"
        [setItemsPerPageAction]="setItemsPerPageAction"
        [setPageAction]="setPageAction">
</ss-pagination>
