<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2022
  ~
  ~ **************************************************************************
  -->

<ss-modal [id]="privacySettingsModalId"
          [size]="'medium'"
          [closeable]="false">
    <ng-template #body>
        <ss-privacy-settings
            [privacySettings]="privacySettings"
            (savePrivacySettings)="handleSave($event)">
        </ss-privacy-settings>
    </ng-template>
</ss-modal>
