/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {
    ChangeDetectionStrategy,
    Component
} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'ss-copyright',
    templateUrl: './copyright.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyrightComponent {
    public copyrightTranslationParams: CopyrightTranslationParams = {
        currentYear: moment().format('YYYY'),
    };
}

export class CopyrightTranslationParams {
    currentYear: string;
}
