<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<ss-supergraphic class="ss-supergraphic__auth"></ss-supergraphic>
<div class="ss-auth-wrapper">
    <div class="ss-auth-wrapper__background"></div>
    <div [class]="classBrandIdentifier">
        <img [src]="srcBrandIdentifier"/>
    </div>
    <div class="ss-auth-wrapper__content">
        <router-outlet></router-outlet>
    </div>
</div>
<ss-footer></ss-footer>
