<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2021
  ~
  ~ **************************************************************************
  -->

<ss-loader [isLoading]="true"
           [attr.data-automation]="'register-loader'">
</ss-loader>
