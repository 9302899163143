/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

export const COLORS = {
    black: '#333333',
    brown: '#ba7354',
    white: '#FFFFFF',
    red: '#ea0016',
    red_12_5: '#fcd9dc',
    red_25: '#fabfc5',
    red_50: '#ea6975',
    magenta: '#b90276',
    magenta_25: '#edc0dd',
    magenta_50: '#dc80ba',
    violet: '#50237f',
    violet_25: '#d3c8df',
    violet_50: '#a791bf',
    dark_blue_125: '#00406d',
    dark_blue: '#005691',
    dark_blue_05: '#e8f1ff',
    dark_blue_12_5: '#d1e4ff',
    dark_blue_25: '#bfd5e3',
    dark_blue_50: '#629cc4',
    light_blue: '#008ecf',
    light_blue_05: '#f2fafe',
    light_blue_12_5: '#d9eef8',
    light_blue_25: '#bfe3f3',
    light_blue_50: '#7fc6e7',
    light_blue_a10: '#008ecf1a',
    light_blue_a30: '#008ecf4d',
    turquoise: '#00a8b0',
    turquoise_25: '#bfe9eb',
    turquoise_50: '#7fd3d7',
    light_green: '#78be20',
    light_green_50: '#bbde8f',
    light_green_80: '#92cd44',
    light_green_12_5: '#ebf6de',
    dark_grey: '#525f6b',
    dark_grey_75: '#7d8790',
    dark_grey_50: '#a8afb5',
    dark_grey_25: '#d4d7da',
    dark_green: '#006249',
    dark_green_25: '#bfd8d1',
    dark_green_50: '#7fb0a4',
    dark_red: '#85000d',
    dark_red_25: '#e2c3c6',
    dark_red_50: '#b3626a',
    dark_turquoise: '#18837e',
    light_grey_500: '#46484b',
    light_grey_125: '#b3b4b6',
    light_grey: '#bfc0c2',
    light_grey_75: '#cfd0d1',
    light_grey_50: '#dfdfe0',
    light_grey_25: '#efeff0',
    light_grey_12_5: '#f7f7f7',
    yellow: '#fcaf17',
    yellow_12_5: '#fff3dc',
    yellow_25: '#feebc5',
    yellow_50: '#fdd78b',
    orange: '#f87f0a',
    purple: '#a05de7',
    purple_25: '#e8d1ff',
    purple_50: '#cb99ff',
    salmon: '#ff998e',
};

export const CRAFT_COLORS: CraftColor[] = [
    {solid: '#d9c200', light: '#f7f3cc'},
    {solid: '#f5a100', light: '#fdeccc'},
    {solid: '#f87f0a', light: '#fde5ce'},
    {solid: '#c96819', light: '#f4e1d1'},
    {solid: '#ff998e', light: '#ffeae8'},
    {solid: '#ff5e45', light: '#ffdfda'},
    {solid: '#b93516', light: '#f1d7d0'},
    {solid: '#ea0016', light: '#fbccd0'},
    {solid: '#c384a1', light: '#f3e6ec'},
    {solid: '#9d4461', light: '#ebdadf'},
    {solid: '#ca3fa1', light: '#f4d9ec'},
    {solid: '#ff60c5', light: '#ffdff3'},
    {solid: '#b90276', light: '#f1cce4'},
    {solid: '#bf00c1', light: '#f2ccf2'},
    {solid: '#8901a2', light: '#e7ccec'},
    {solid: '#a05de7', light: '#ecdffa'},
    {solid: '#7d38c7', light: '#e5d7f4'},
    {solid: '#50237f', light: '#dcd3e5'},
    {solid: '#7769ff', light: '#e4e1ff'},
    {solid: '#5641c3', light: '#ddd9f3'},
    {solid: '#779ef5', light: '#e4ebfd'},
    {solid: '#5275a1', light: '#dce3ec'},
    {solid: '#005691', light: '#ccdde9'},
    {solid: '#008ecf', light: '#cce8f5'},
    {solid: '#0bd3d1', light: '#cef6f6'},
    {solid: '#00a8b0', light: '#ccedef'},
    {solid: '#006249', light: '#cce0db'},
    {solid: '#2fb858', light: '#d5f1de'},
    {solid: '#1e8a3e', light: '#d2e7d8'},
    {solid: '#78be20', light: '#e4f2d2'},
    {solid: '#859425', light: '#e6e9d3'},
    {solid: '#606a1b', light: '#dfe1d1'},
    {solid: '#b99363', light: '#f1e9e0'},
    {solid: '#896a43', light: '#e7e1d9'},
    {solid: '#ba7354', light: '#f1e3dd'},
    {solid: '#63372e', light: '#e0d7d5'},
    {solid: '#5e4b42', light: '#dfdbd9'},
    {solid: '#868686', light: '#e7e7e7'},
    {solid: '#525f6b', light: '#dcdfe1'},
    {solid: '#3d464f', light: '#d8dadc'},
];

export const COLORS_ALPHA_PERCENTAGE = 20;

export class CraftColor {
    solid: string;
    light: string;
}
