<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2022
  ~
  ~ **************************************************************************
  -->

<div class="ss-task-card-week-placeholder"
     [id]="taskCardPlaceholderIdPrefix + taskId"
     [ngStyle]="taskStyles"
     [attr.data-automation]="'task-card-week-placeholder'">
</div>
