<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-day-card-create">
    <ss-loader [isLoading]="isLoading"></ss-loader>
    <ss-day-card-capture #dayCardCapture
                         [mode]="captureMode"
                         [dateConfig]="dateConfig"
                         [defaultValues]="defaultValues"
                         (onSubmit)="handleSubmit($event)"
                         (onCancel)="handleCancel()">
    </ss-day-card-capture>
</div>
