/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {Component} from '@angular/core';

@Component({
    selector: 'ss-project-task-detail',
    templateUrl: './project-task-detail.component.html'
})
export class ProjectTaskDetailComponent {
}
