<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2022
  ~
  ~ **************************************************************************
  -->

<ss-icon [name]="iconName"
         [color]="iconColor"
         [dimension]="dimension">
</ss-icon>
