<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<section>
    <ss-profile [profile]="profile" [showToolbar]="false"></ss-profile>
</section>
