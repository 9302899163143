<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-task">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <ss-project-task-detail></ss-project-task-detail>
            </div>
            <div class="col-md-12 col-lg-6">
                <ss-project-task-workflow></ss-project-task-workflow>
            </div>
        </div>
    </div>
</div>
