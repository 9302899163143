/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {Component} from '@angular/core';

@Component({
    template: ''
})
export class StickyMirrorComponent {
}
