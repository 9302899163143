<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="container-no-padding">
    <ss-project-crafts-toolbar></ss-project-crafts-toolbar>
</div>
<div class="ss-project-crafts__list-container">
    <ss-project-crafts-list></ss-project-crafts-list>
</div>
