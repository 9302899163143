<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<svg class="ss-tiny-loader" [ngClass]="modeClasses" viewBox="0 0 100 100">
    <rect class="ss-tiny-loader__square ss-tiny-loader__square--left" width="25" height="25"></rect>
    <rect class="ss-tiny-loader__square ss-tiny-loader__square--right" width="25" height="25"></rect>
</svg>
