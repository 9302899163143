/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import {Component} from '@angular/core';

@Component({
    templateUrl: './project-rfv.component.html',
    styleUrls: ['./project-rfv.component.scss']
})
export class ProjectRfvComponent {

    constructor() {
    }

}
