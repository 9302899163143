<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-project-tasks-sort-capture">
    <ss-sorting [sorterDataSelectorFunction]="sorterDateSelectorFunction"
                [setSortAction]="setSortAction"
                [sortMethods]="sortMethods"
                (onClose)="handleCancel()">
    </ss-sorting>
</div>
