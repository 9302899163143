<!--
  ~ **************************************************************************
  ~
  ~ Copyright: Robert Bosch GmbH, 2020
  ~
  ~ **************************************************************************
  -->

<div class="ss-task-craft-label">
    <div class="ss-task-craft-label__color"
         [ngStyle]="{'background-color': craft.color}"
         [attr.data-automation]="'task-craft-label-color'">
    </div>
    <span class="ss-task-craft-label__name"
          [attr.data-automation]="'task-craft-label-name'">
        {{craft.name}}
    </span>
</div>
